import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Webservices } from '../../constants/routes/webservices.constant';
import { AbstractHttpService, Response } from './abstract-http.service';
import { HomeDto } from '../../models/dto/home-dto';

@Injectable({
  providedIn: 'root',
})
export class HomeHttpService extends AbstractHttpService {
  protected constructor(_http: HttpClient) {
    super(_http);
  }

  public getFigures(year?: number): Observable<HomeDto | null> {
    return this.get<HomeDto>(Webservices.home, {
      response: Response.json,
      attrs: { year },
    });
  }
}
