import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { LoaderComponent } from './components/ui/loader/loader.component';
import { MenuComponent } from './components/ui/menu/menu.component';
import { RoutePipe } from './pipes/route.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@angular/cdk/dialog';
import { HttpClientModule } from '@angular/common/http';
import { IconButtonComponent } from './components/ui/icon-button/icon-button.component';
import { IconComponent } from './components/ui/icon/icon.component';
import { SnackbarComponent } from './components/ui/snackbar/snackbar.component';
import { RippleDirective } from './directives/ripple.directive';
import { SlideToggleDirective } from './directives/slide-toggle.directive';
import { BurgerComponent } from './components/ui/burger/burger.component';
import { SnackComponent } from './components/ui/snackbar/snack/snack.component';
import { PopinLayoutComponent } from './components/layouts/popin-layout/popin-layout.component';
import { ChartsLayoutComponent } from './components/layouts/charts-layout/charts-layout.component';
import { ShareDialogComponent } from './components/dialogs/share-dialog/share-dialog.component';
import { ButtonComponent } from './components/ui/button/button.component';
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { SelectComponent } from './components/form/select/select.component';
import { ClickAwayDirective } from './directives/click-away.directive';
import { InArrayPipe } from './pipes/arrays/in-array.pipe';
import { JoinArrayPipe } from './pipes/arrays/join-array.pipe';
import { TextInputComponent } from './components/form/text-input/text-input.component';
import { ChipComponent } from './components/ui/chip/chip.component';
import { PieComponent } from './components/ui/pie/pie.component';
import { LineChartDirective } from './directives/charts/line-chart.directive';
import { VerticalBarChartDirective } from './directives/charts/vertical-bar-chart.directive';
import { HorizontalBarChartDirective } from './directives/charts/horizontal-bar-chart.directive';
import { DualHorizontalBarChartDirective } from './directives/charts/dual-horizontal-bar-chart.directive';
import { FiltersComponent } from './components/ui/filters/filters.component';
import { CompareDialogComponent } from './components/dialogs/compare-dialog/compare-dialog.component';
import { SubmenuComponent } from './components/ui/submenu/submenu.component';
import { CardComponent } from './components/ui/card/card.component';
import { SummaryComponent } from './components/ui/summary/summary.component';
import { PieChartDirective } from './directives/charts/pie-chart.directive';

@NgModule({
  declarations: [
    LoaderComponent,
    MenuComponent,
    RoutePipe,
    IconComponent,
    IconButtonComponent,
    BurgerComponent,
    SnackbarComponent,
    SnackComponent,
    RippleDirective,
    SlideToggleDirective,
    PopinLayoutComponent,
    ChartsLayoutComponent,
    ShareDialogComponent,
    ButtonComponent,
    InfoDialogComponent,
    SelectComponent,
    ClickAwayDirective,
    InArrayPipe,
    JoinArrayPipe,
    TextInputComponent,
    ChipComponent,
    PieComponent,
    LineChartDirective,
    VerticalBarChartDirective,
    HorizontalBarChartDirective,
    DualHorizontalBarChartDirective,
    FiltersComponent,
    CompareDialogComponent,
    SubmenuComponent,
    CardComponent,
    SummaryComponent,
    PieChartDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    HttpClientModule,
  ],
  exports: [
    MenuComponent,
    LoaderComponent,
    IconComponent,
    IconButtonComponent,
    BurgerComponent,
    SnackbarComponent,
    RippleDirective,
    SlideToggleDirective,
    PopinLayoutComponent,
    ChartsLayoutComponent,
    ButtonComponent,
    SelectComponent,
    ClickAwayDirective,
    InArrayPipe,
    JoinArrayPipe,
    TextInputComponent,
    ChipComponent,
    PieComponent,
    LineChartDirective,
    VerticalBarChartDirective,
    HorizontalBarChartDirective,
    DualHorizontalBarChartDirective,
    CompareDialogComponent,
    CardComponent,
    SummaryComponent,
    PieChartDirective,
  ],
  providers: [DecimalPipe],
})
export class SharedModule {}
