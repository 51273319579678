import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  asyncScheduler,
  fromEvent,
  startWith,
  throttleTime,
} from 'rxjs';

export enum Breakpoints {
  xSmall = 599,
  small = 959,
  medium = 1279,
  large = 1919,
  xLarge = -1,
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private readonly _breakpoint$: BehaviorSubject<Breakpoints>;

  public constructor(@Inject(DOCUMENT) private readonly _document: Document) {
    this._breakpoint$ = new BehaviorSubject<Breakpoints>(Breakpoints.xLarge);
  }

  public bootstrap(): void {
    fromEvent(this._document.defaultView ?? new HTMLElement(), 'resize')
      .pipe(
        startWith(undefined),
        throttleTime(50, asyncScheduler, { leading: true, trailing: true })
      )
      .subscribe((e?: Event) => {
        this.updateBreakpoint();
      });
  }

  public get breakpoint(): Breakpoints {
    return this._breakpoint$.value;
  }

  public get breakpoint$(): Observable<Breakpoints> {
    return this._breakpoint$.asObservable();
  }

  public get tablet(): boolean {
    return !!(
      this._breakpoint$.value &&
      this._breakpoint$.value <= Breakpoints.small &&
      this._breakpoint$.value >= Breakpoints.xSmall
    );
  }

  public get mobile(): boolean {
    return !!(
      this._breakpoint$.value > 0 &&
      this._breakpoint$.value &&
      this._breakpoint$.value <= Breakpoints.xSmall
    );
  }

  private updateBreakpoint(): void {
    const width: number | undefined = this._document.body.clientWidth;
    let breakpoint: Breakpoints = Breakpoints.xLarge;
    switch (true) {
      case width <= Breakpoints.xSmall:
        breakpoint = Breakpoints.xSmall;
        break;
      case width <= Breakpoints.small:
        breakpoint = Breakpoints.small;
        break;
      case width <= Breakpoints.medium:
        breakpoint = Breakpoints.medium;
        break;
      case width <= Breakpoints.large:
        breakpoint = Breakpoints.large;
        break;
    }

    if (breakpoint !== this._breakpoint$.value) {
      this._breakpoint$.next(breakpoint);
    }
  }
}
