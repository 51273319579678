import { Component, OnInit } from '@angular/core';
import { combineLatest, debounceTime, takeUntil } from 'rxjs';
import { AbstractComponent } from './shared/components/abstract.component';
import { AppStateService } from './shared/services/app-state.service';
import { reattach } from './shared/utils/rxjs.utils';
import { Metadata } from './shared/models/sys/metadata';
import { SeoService } from './shared/services/html/seo.service';
import { TrackingService } from './shared/services/html/tracking.service';
import { ResponsiveService } from './shared/services/html/responsive.service';
import { BootstrapHttpService } from './shared/services/http/bootstrap-http.service';
import { BootstrapDto } from './shared/models/dto/bootstrap-dto';
import { SnackbarService } from './shared/services/snackbar.service';
import { HomeHttpService } from './shared/services/http/home-http.service';
import { HomeDto } from './shared/models/dto/home-dto';
import { AppDialogService } from './shared/services/app-dialog.service';
import { AppRouterService } from './shared/services/app-router.service';
import { RegionDto } from './shared/models/dto/region-dto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AbstractComponent implements OnInit {
  public loading: boolean = false;

  public constructor(
    private readonly _appState: AppStateService,
    private readonly _bootstrap: BootstrapHttpService,
    private readonly _home: HomeHttpService,
    private readonly _snack: SnackbarService,
    private readonly _seo: SeoService,
    private readonly _tracking: TrackingService,
    private readonly _responsive: ResponsiveService,
    private readonly _dialog: AppDialogService,
    private readonly _router: AppRouterService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._responsive.bootstrap();
    this._appState.loading$
      .pipe(takeUntil(this._destroyed$), reattach(), debounceTime(10))
      .subscribe((queue: number) => {
        this.loading = !!queue;
      });

    this._appState.metadata$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((metadata?: Metadata) => {
        this._seo.title = metadata?.title;
        this._seo.description = metadata?.description;
        this._seo.keywords = metadata?.keywords;
        this._seo.author = metadata?.author;
        this._seo.image = metadata?.image;
        this._seo.robots = metadata?.robots;
        this._seo.setCanonicalURL(metadata?.canonical);

        if (metadata) {
          this._tracking.trackPage();
        }
      });

    this._seo.bootstrap();
    this._tracking.bootstrap();

    combineLatest([
      this._bootstrap.bootstrap(),
      this._home.getFigures(),
    ]).subscribe(
      ([bootstrap, figures]: [BootstrapDto | null, HomeDto | null]) => {
        if (!bootstrap) {
          this._snack.error(
            "Une erreur est survenue lors du chargement des données de l'application."
          );
          return;
        }
        bootstrap.regions.sort((a: RegionDto, b: RegionDto) =>
          a.label.localeCompare(b.label)
        );
        bootstrap.departments.sort((a: RegionDto, b: RegionDto) =>
          a.label.localeCompare(b.label)
        );
        this._appState.globalFilters = bootstrap;
        this._appState.homeFigures = figures ?? undefined;
      }
    );
  }
}
