import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export enum TrackingEvent {
  share = 'Partage',
}

export enum TrackingAction {
  download = 'Télécharger',
  copy = 'Copier',
}

interface MatomoWindow extends Window {
  _paq?: unknown[];
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  public constructor(
    private readonly _title: Title,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  private get _paq(): unknown[] {
    const window: MatomoWindow = this._document.defaultView as MatomoWindow;
    window._paq = window._paq ?? [];

    return window._paq;
  }

  public bootstrap(): void {
    this._paq.push(['setTrackerUrl', environment.matomo + '/matomo.php']);
    this._paq.push(['setSiteId', environment.matomoSiteId]);

    const script: HTMLElement = this._document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', 'true');
    script.setAttribute('src', environment.matomo + '/matomo.js');
    this._document.head.appendChild(script);
  }

  public trackPage(): void {
    const window: MatomoWindow = this._document.defaultView as MatomoWindow;
    const url: string =
      (window?.location?.pathname ?? '') + (window?.location?.search ?? '');

    // Track
    this._paq.push(['deleteCustomVariables', 'page']);
    this._paq.push(['deleteCustomDimension', 1]);
    this._paq.push(['setCustomUrl', url]);
    this._paq.push(['setDocumentTitle', this._title.getTitle()]);
    // this._paq.push(['setReferrerUrl', {{Referrer}}]);
    this._paq.push(['trackPageView']);
  }

  public trackEvent(
    category: TrackingEvent,
    action: TrackingAction,
    name: string,
    value?: string
  ): void {
    this._paq.push(['trackEvent', category, action, name, value]);
  }
}
