<div class="inner">
  <div class="top">
    <button app-burger [close]="close"></button>
    <a class="logo" routerLink="/">
      <img
        src="assets/images/logo.svg"
        alt="Logo de l'Observatoire des métiers du BTP" />
    </a>
  </div>
  <ul>
    <li>
      <a
        class="menu-item"
        [routerLink]="routes.home | route"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <app-icon src="assets/images/icons/home.svg"></app-icon>
        <span>Accueil</span>
      </a>
    </li>
    <li>
      <a
        class="menu-item"
        [routerLink]="routes.companies | route"
        routerLinkActive="active">
        <app-icon src="assets/images/icons/companies.svg"></app-icon>
        <span>Les établissements du BTP</span>
      </a>
      <ul
        [appSlideToggle]="current === sections.companies"
        [appSlideToggleTiming]="300">
        <li>
          <a
            [routerLink]="routes.companiesSector | route"
            routerLinkActive="active"
            >Branche</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.companiesActivity | route"
            routerLinkActive="active"
            >Activité</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.companiesSize | route"
            routerLinkActive="active"
            >Taille d’établissement</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.companiesLocation | route"
            routerLinkActive="active"
            >Localisation</a
          >
        </li>
      </ul>
    </li>
    <li>
      <a
        class="menu-item"
        [routerLink]="routes.employees | route"
        routerLinkActive="active">
        <app-icon src="assets/images/icons/employees.svg"></app-icon>
        <span>Les salariés du BTP</span>
      </a>
      <ul
        [appSlideToggle]="current === sections.employees"
        [appSlideToggleTiming]="300">
        <li>
          <a
            [routerLink]="routes.employeesActivity | route"
            routerLinkActive="active"
            >Activité</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.employeesGender | route"
            routerLinkActive="active"
            >Sexe</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.employeesEvolutionWomen | route"
            routerLinkActive="active"
            >Part des femmes</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.employeesAge | route"
            routerLinkActive="active"
            >Âge</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.employeesLocation | route"
            routerLinkActive="active"
            >Localisation</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.employeesJob | route"
            routerLinkActive="active"
            >Métier</a
          >
        </li>
        <li>
          <a
            [routerLink]="routes.employeesJobEvolution | route"
            routerLinkActive="active"
            >Evolution du métier</a
          >
        </li>
      </ul>
    </li>
    <li>
      <a
        class="menu-item"
        [routerLink]="routes.interns | route"
        routerLinkActive="active">
        <app-icon src="assets/images/icons/interns.svg"></app-icon>
        <span>La formation continue des salariés</span>
      </a>
      <ul
        [appSlideToggle]="current === sections.interns"
        [appSlideToggleTiming]="300">
        <li>
          <a
            routerLinkActive="active"
            [routerLink]="routes.internsEvolution | route"
            >Evolution</a
          >
        </li>
        <li>
          <a
            routerLinkActive="active"
            [routerLink]="routes.internsCategory | route"
            >Thématique</a
          >
        </li>
      </ul>
    </li>
    <li>
      <a
        class="menu-item"
        [routerLink]="routes.selfEmployed | route"
        routerLinkActive="active">
        <app-icon src="assets/images/icons/self-employed.svg"></app-icon>
        <span>La formation continue des TNS du Bâtiment</span>
      </a>
      <ul
        [appSlideToggle]="current === sections.selfEmployed"
        [appSlideToggleTiming]="300">
        <li>
          <a
            routerLinkActive="active"
            [routerLink]="routes.selfEmployedCategory | route"
            >Thématique</a
          >
        </li>
        <li>
          <a
            routerLinkActive="active"
            [routerLink]="routes.selfEmployedKeyFigures | route"
            >Chiffres clés</a
          >
        </li>
      </ul>
    </li>
    <li>
      <a
        class="menu-item"
        [routerLink]="routes.professionals | route"
        routerLinkActive="active">
        <app-icon src="assets/images/icons/work-studies.svg"></app-icon>
        <span>La formation en alternance</span>
      </a>
      <ul
        [appSlideToggle]="current === sections.workStudy"
        [appSlideToggleTiming]="300">
        <li>
          <span class="submenu">Contrats d'apprentissage</span>
          <ul>
            <li>
              <a
                [routerLink]="routes.apprenticesEvolution | route"
                routerLinkActive="active"
                >Evolution</a
              >
            </li>
            <li>
              <a
                [routerLink]="routes.apprenticesDegree | route"
                routerLinkActive="active"
                >Diplôme</a
              >
            </li>
            <li>
              <a
                [routerLink]="routes.apprenticesSector | route"
                routerLinkActive="active"
                >Domaine de formation</a
              >
            </li>
            <li>
              <a
                [routerLink]="routes.apprenticesActivity | route"
                routerLinkActive="active"
                >Activité</a
              >
            </li>
            <li>
              <a
                [routerLink]="routes.apprenticesAge | route"
                routerLinkActive="active"
                >Âge</a
              >
            </li>
          </ul>
        </li>
        <li>
          <span class="submenu">Contrats de professionnalisation</span>
          <ul>
            <li>
              <a
                [routerLink]="routes.professionalsEvolution | route"
                routerLinkActive="active"
                >Evolution</a
              >
            </li>
            <li>
              <a
                [routerLink]="routes.professionalsSector | route"
                routerLinkActive="active"
                >Domaine de formation</a
              >
            </li>
            <li>
              <a
                [routerLink]="routes.professionalsActivity | route"
                routerLinkActive="active"
                >Activité</a
              >
            </li>
            <li>
              <a
                [routerLink]="routes.professionalsAge | route"
                routerLinkActive="active"
                >Âge</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      <a
        class="menu-item"
        [routerLink]="routes.students | route"
        routerLinkActive="active">
        <app-icon src="assets/images/icons/students.svg"></app-icon>
        <span>La formation initiale</span>
      </a>
      <ul
        [appSlideToggle]="current === sections.students"
        [appSlideToggleTiming]="300">
        <li>
          <a routerLinkActive="active" [routerLink]="routes.students | route"
            >Domaine de spécialité</a
          >
        </li>
      </ul>
    </li>
  </ul>
</div>
